import PropTypes from "prop-types";

import { formatDate } from "common/functions";

import { FiCalendar } from "components/atoms/Icon/fi/FiCalendar";
import Img from "components/atoms/Img";
import Link from "components/atoms/LinkRouter";
import Title from "components/atoms/Title";

/** ========== */

const PostItem = ({ post, layout }) => {
  const category = post?.categories?.nodes.filter(
    (item) => item.parent === null
  );

  if (layout === "vertical") {
    return (
      <div className="flex h-full flex-1 flex-col rounded-3xl bg-white transition-shadow duration-150 hover:shadow-[0px_0px_20px_#7c787833] xl:max-w-sm">
        {/* <LazyLoad> */}
        <Img
          image={post?.featuredImage?.node}
          wrapper={{ className: "w-full relative" }}
          className="aspect-[16/9] h-full w-full rounded-t-3xl object-cover"
        />
        {/* </LazyLoad> */}

        <div className="flex flex-1 flex-col justify-between p-6">
          <div>
            <Title
              as="h3"
              className="text-lg font-semibold text-bbc-brand-800 line-clamp-2"
            >
              <Link href={post?.uri}>{post?.title}</Link>
            </Title>
            <div
              className="mt-4 line-clamp-3"
              dangerouslySetInnerHTML={{ __html: post?.excerpt }}
            />
          </div>
          <div className="flex flex-wrap justify-between">
            <div className="mt-6 inline-flex items-center rounded-md bg-bbc-brand-800 px-2 py-0.5">
              <Img
                src={`/assets/icons/${category[0].name.toLowerCase()}.svg`}
                alt="icon"
                width={16}
                height={16}
              />
              <span className="inline-block pl-[5px] text-sm font-medium text-white">
                {category.length > 0 && category[0].name}
              </span>
            </div>
            <div className="mt-6 inline-flex items-center">
              <FiCalendar size={18} />
              <span className="inline-block pl-2 text-sm">
                {formatDate(post?.date)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (layout === "horizontal") {
    return (
      <div>
        <div className="text-bbc-brand-700">
          <Title as="h3" className="text-lg font-medium line-clamp-2">
            <Link href={post?.uri}>{post?.title}</Link>
          </Title>
          <div className="mt-6 inline-flex items-center">
            <FiCalendar size={18} />
            <span className="inline-block pl-2 text-sm">
              {formatDate(post?.date)}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default PostItem;

PostItem.propTypes = {
  post: PropTypes.object,
  layout: PropTypes.string,
};

PostItem.defaultProps = {
  post: {},
  layout: "vertical",
};

/** ========== */
