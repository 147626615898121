import { Fragment } from "react";
import LazyLoad from "react-lazyload";

import dynamic from "next/dynamic";

import PropTypes from "prop-types";

import { formatDate } from "common/functions";

import { FiCalendar } from "components/atoms/Icon/fi/FiCalendar";
import Img from "components/atoms/Img";
import Link from "components/atoms/LinkRouter";
import Title from "components/atoms/Title";
import PostItem from "components/molecules/PostItem";
const INISlider = dynamic(() => import("./INISlider"), { ssr: false });
/** ========== */

const IndustryNewsInsights = ({ data }) => {
  const category = data[0]?.categories?.nodes.filter(
    (item) => item.parent === null
  );

  return (
    <>
      <section className="hidden lg:block">
        <div className="container">
          <Title
            as="h2"
            text="Industry News & Insights"
            className="mb-10 text-center text-bbc-40px font-semibold text-bbc-brand-800"
          />
          <div className="row">
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <Fragment key={index}>
                    {index === 0 ? (
                      <div className="col-12">
                        <div className="rounded-3xl bg-white transition-shadow duration-150 hover:shadow-[0px_0px_20px_#7c787833]">
                          <div className="flex">
                            <div className="col-8 pr-[15px]">
                              {/* <LazyLoad> */}
                              <Img
                                image={data[0]?.featuredImage?.node}
                                className="rounded-l-3xl"
                                wrapper={{ className: "block" }}
                              />
                              {/* </LazyLoad> */}
                            </div>
                            <div className="col-4 flex flex-col justify-center px-[15px] py-4">
                              <Title
                                as="h3"
                                className="text-xl font-semibold leading-8 text-bbc-brand-800 line-clamp-3"
                              >
                                <Link href={data[0]?.uri}>
                                  {data[0]?.title}
                                </Link>
                              </Title>
                              <div
                                className="mt-6 text-lg text-bbc-brand-900 line-clamp-3"
                                dangerouslySetInnerHTML={{
                                  __html: data[0]?.excerpt,
                                }}
                              />
                              <div className="mt-6 flex justify-between">
                                <div className="inline-flex items-center rounded-md bg-bbc-brand-800 px-2 py-0.5">
                                  <Img
                                    src={`/assets/icons/${category[0].name.toLowerCase()}.svg`}
                                    alt="icon"
                                    width={16}
                                    height={16}
                                  />
                                  <span className="inline-block pl-[5px] text-sm font-medium text-white">
                                    {category.length > 0 && category[0].name}
                                  </span>
                                </div>
                                <div className="inline-flex items-center">
                                  <FiCalendar size={18} />
                                  <span className="inline-block pl-2 text-sm text-bbc-gray">
                                    {formatDate(data[0]?.date)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-4 mt-10">
                        <PostItem post={item} />
                      </div>
                    )}
                  </Fragment>
                );
              })}
          </div>
        </div>
      </section>

      <section className="lg:hidden">
        <div className="container">
          <Title className="title-h2 m-auto mb-10 w-2/3 pb-0 text-center text-bbc-brand-800">
            Industry News & Insights
          </Title>
          <LazyLoad
            once
            offset={[-100, 100]}
            placeholder={<PostItem post={data[0]} />}
          >
            <INISlider data={data} />
          </LazyLoad>
        </div>
      </section>
    </>
  );
};

export default IndustryNewsInsights;

IndustryNewsInsights.propTypes = {
  data: PropTypes.array,
};

IndustryNewsInsights.defaultProps = {
  data: [],
};

/** ========== */
