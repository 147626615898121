import { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";

import PropTypes from "prop-types";

import Img from "components/atoms/Img";
import Title from "components/atoms/Title";

const AboutUs = ({ data, bgImage }) => {
  const [isCount, setIsCount] = useState(false);

  useEffect(() => {
    const detech = () => {
      const sectionAboutUs = document.querySelector("#section-about-us");

      if (sectionAboutUs) {
        const sectionAboutUsOffsetTop = sectionAboutUs.offsetTop + 60;

        if (
          window.pageYOffset >
          sectionAboutUsOffsetTop - sectionAboutUsOffsetTop / 2
        ) {
          setIsCount(true);
        }
      }
    };

    window.addEventListener("scroll", () => {
      detech();
    });
    window.addEventListener("resize", () => {
      detech();
    });
  }, []);

  const animateCountUp = (el) => {
    const animationDuration = 3000;

    const frameDuration = 1000 / 60;

    const totalFrames = Math.round(animationDuration / frameDuration);

    const easeOutQuad = (t) => t * (2 - t);
    let frame = 0;
    const countTo = parseInt(el.innerHTML, 10);

    const counter = setInterval(() => {
      frame++;

      const progress = easeOutQuad(frame / totalFrames);

      const currentCount = Math.round(countTo * progress);

      if (parseInt(el.innerHTML, 10) !== currentCount) {
        // eslint-disable-next-line no-param-reassign
        el.innerHTML = currentCount;
      }

      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  };

  useEffect(() => {
    if (isCount) {
      const counters = document.querySelectorAll(".count");

      counters.forEach(animateCountUp);
    }
  }, [isCount]);

  return (
    <>
      <section
        id="section-about-us"
        className="relative bg-bbc-gradient-liner py-10 lg:py-20"
      >
        {bgImage && (
          <LazyLoad>
            <Img
              src={bgImage}
              alt="bg about us"
              width={1920}
              height={520}
              className="absolute top-0 left-0 bottom-0 right-0 h-full w-full"
            />
          </LazyLoad>
        )}
        <div className="container relative z-10">
          <Title
            text={data?.title}
            className="title-h2 mb-6 text-white lg:mb-10"
          />
          <div className="row items-center">
            <div className="col-12 lg:col-7">
              <div
                className="leading-bbc-26 text-white"
                dangerouslySetInnerHTML={{ __html: data?.description }}
              />
            </div>
            <div className="col-12 mt-7 lg:col-5 lg:mt-0">
              <div className="flex flex-wrap text-white">
                <div className="col-6 text-center">
                  <p className="mb-4 text-bbc-40px font-semibold lg:text-bbc-48px">
                    <span className="count text-bbc-secondary-300">97</span>
                    <span className="text-bbc-secondary-300">{"% "}</span>
                    <span>+</span>
                  </p>
                  <p className="text-lg">Recommend us</p>
                </div>
                <div className="col-6 relative text-center before:absolute before:top-4 before:bottom-4 before:left-0 before:w-[1px] before:bg-white before:content-[``]">
                  <p className="mb-4 text-bbc-40px font-semibold lg:text-bbc-48px">
                    <span className="count text-bbc-secondary-300">12</span>{" "}
                    <span>+</span>
                  </p>
                  <p className="text-lg">Years in Business</p>
                </div>
                <div className="col-6 mt-10 text-center">
                  <p className="mb-4 text-bbc-40px font-semibold lg:text-bbc-48px">
                    <span className="count text-bbc-secondary-300">9000</span>{" "}
                    <span>+</span>
                  </p>
                  <p className="text-lg">Successful cases</p>
                </div>
                <div className="col-6 relative mt-10 text-center before:absolute before:top-4 before:bottom-4 before:left-0 before:w-[1px] before:bg-white before:content-[``]">
                  <p className="mb-4 text-bbc-40px font-semibold lg:text-bbc-48px">
                    <span className="count text-bbc-secondary-300">2</span>{" "}
                    <span>Hrs</span>
                  </p>
                  <p className="text-lg">Response within</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;

AboutUs.propTypes = {
  data: PropTypes.object,
  bgImage: PropTypes.string,
};

AboutUs.defaultProps = {
  data: {},
};
