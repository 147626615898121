import React, { useState } from "react";

import { Autoplay, FreeMode, Navigation, Pagination, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";

import { GrFormNext } from "components/atoms/Icon/gr/GrFormNext";
import { SiTrustpilot } from "components/atoms/Icon/si/SiTrustpilot";
import Img from "components/atoms/Img";

import styles from "./Index.module.scss";

const dataStar = new Array(5).fill(1);

const swiper = {
  preloadImages: false,
  lazy: true,
  loop: false,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  pagination: {
    el: ".swiper-trustpilot-dots",
    clickable: true,
    bulletClass: "bbc-pagination-bullet",
    bulletActiveClass: "bbc-pagination-bullet-active",
  },
  navigation: {
    nextEl: ".swiper-trustpilot-next",
    prevEl: ".swiper-trustpilot-prev",
  },
  slidesPerView: 1,
  spaceBetween: 40,
};

const Component = ({ data }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  function isImage(url) {
    // eslint-disable-next-line prefer-named-capture-group
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }

  return (
    <div className="mt-6 justify-between lg:mt-10 lg:flex">
      <div className="col-12 lg:col-6">
        <Swiper
          spaceBetween={10}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[Autoplay, Navigation, Thumbs, Pagination]}
          {...swiper}
          className="swiperFeelback bg-bbc-main "
        >
          {data.map((item, index) => (
            <SwiperSlide className="p-3 lg:p-0" key={index}>
              <div className=" w-full rounded-3xl bg-white py-6 px-8 shadow-[0px_0px_12px_rgba(119,119,119,0.25)] lg:rounded-none  lg:bg-transparent lg:p-0 lg:shadow-none">
                <div className="flex justify-center lg:justify-start">
                  {isImage(item.avatar) ? (
                    <Img
                      src={item.avatar}
                      className="mr-6 rounded-full"
                      alt={item.name}
                      width={80}
                      height={80}
                    />
                  ) : (
                    <div
                      className={`${styles.avatar} mr-6 flex items-center justify-center rounded-full font-bold`}
                      style={{ backgroundColor: item.bgAvatar }}
                    >
                      {item.avatar}
                    </div>
                  )}

                  <div>
                    <p className="mb-2  text-bbc-neutral-700">{item.name}</p>
                    <div className="flex">
                      {dataStar.map((item, index) => {
                        return (
                          <div
                            className="mr-1 flex h-6 w-6 items-center justify-center bg-[#00b67a] shadow-md"
                            key={index}
                          >
                            <SiTrustpilot className="text-xl text-bbc-white" />
                          </div>
                        );
                      })}
                    </div>
                    <div className="align-items-end mt-3  flex">
                      <div className="mr-0.5">
                        <SiTrustpilot className="text-2xl text-[#00b67a]" />
                      </div>
                      <div className="text-xl font-medium text-bbc-black">
                        Trustpilot
                      </div>
                    </div>
                  </div>
                </div>
                <p className="my-4 text-center font-semibold text-[#0042A3] opacity-[0.8] lg:text-left">
                  {item.title}
                </p>
                <p className=" text-center text-bbc-gray lg:text-left">
                  {item.content}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="relative  text-center lg:col-6">
        <div className=" hidden lg:flex lg:justify-center">
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={5}
            slidesPerView={3}
            freeMode={true}
            allowTouchMove={false}
            modules={[FreeMode, Navigation, Thumbs, Pagination]}
            className={"swiperAvatar col-5 mr-2.5 ml-0"}
          >
            {data.map((item, index) => {
              return (
                <SwiperSlide key={index} className="text-center">
                  {isImage(item.avatar) ? (
                    <Img
                      src={item.avatar}
                      className="cursor-pointer rounded-full"
                      alt="avatar"
                      width={60}
                      height={60}
                    />
                  ) : (
                    <div
                      className={
                        "mx-auto flex  items-center justify-center rounded-full text-2xl font-bold"
                      }
                      style={{
                        width: "60px",
                        height: "60px",
                        backgroundColor: item.bgAvatar,
                      }}
                    >
                      {item.avatar}
                    </div>
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className={" mt-6 hidden h-16 lg:block"}>
          <div
            className={`swiper-trustpilot-next absolute flex h-16 w-16 cursor-pointer items-center justify-center rounded-full ${styles.btnNext} `}
          >
            <GrFormNext className=" text-3xl" />
          </div>
          <div
            className={`swiper-trustpilot-prev absolute  flex h-16 w-16 cursor-pointer items-center justify-center rounded-full ${styles.btnPrev} `}
          >
            <GrFormNext className="rotate-180 text-3xl" />
          </div>
        </div>

        <div className="swiper-trustpilot-dots  pt-6 "></div>
      </div>
    </div>
  );
};

export default Component;
