import { useEffect, useRef } from "react";

import useIntersectionObserver from "common/hooks/useIntersectionObserver";

import Bubble from "./Bubble";

/* ********** */

const bubbles = [
  // 1
  new Bubble(1, 0, 0, "0px", "0%", 0.75), // Belize bank
  new Bubble(2, 0, 1, "120px", "25%", 0.5), // Cayman bank
  new Bubble(3, 0, 2, "25px", "65%", 1), // Heritage bank
  new Bubble(4, 0, 3, "220px", "55%", 0.75), // CIBC bank
  new Bubble(5, 0, 4, "280px", "15%", 0.5), // BEA bank
  new Bubble(6, 0, 5, "350px", "75%", 0.75), // DBS bank
  // 2
  new Bubble(7, 1, 0, "490px", "45%", 1), // Fubon bank
  new Bubble(8, 1, 1, "420px", "0%", 0.75), // Hangseng bank
  new Bubble(9, 1, 2, "600px", "0%", 0.5), // HSBC bank
  new Bubble(10, 1, 3, "650px", "70%", 0.75), // OCBC bank
  new Bubble(11, 1, 4, "700px", "30%", 0.75), // Standard bank
  new Bubble(12, 1, 5, "830px", "0%", 1), // CITI bank
  // 3
  // New Bubble(13, 2, 0, "800px", "70%", 0.5),
  new Bubble(14, 2, 1, "920px", "45%", 0.75),
  new Bubble(15, 2, 2, "1020px", "10%", 0.5),
  new Bubble(16, 2, 3, "1050px", "70%", 0.5),
  new Bubble(17, 2, 4, "1170px", "45%", 1),
  new Bubble(18, 2, 5, "1200px", "0%", 0.75),
  // 4
  new Bubble(19, 3, 0, "1320px", "70%", 0.75),
  new Bubble(20, 3, 1, "1340px", "20%", 0.5),
  new Bubble(21, 3, 2, "1450px", "45%", 0.5),
  new Bubble(22, 3, 3, "1500px", "0%", 1),
  new Bubble(23, 3, 4, "1670px", "20%", 0.5),
  new Bubble(24, 3, 5, "1620px", "65%", 0.75),
  // // // 5
  new Bubble(25, 4, 0, "1790px", "0%", 0.75),
  new Bubble(26, 4, 1, "1820px", "60%", 1),
  new Bubble(27, 4, 2, "1950px", "10%", 0.5),
  new Bubble(28, 4, 3, "1990px", "60%", 0.5),
  new Bubble(29, 4, 4, "2120px", "10%", 1),
  new Bubble(30, 4, 5, "2150px", "60%", 0.75),
  // // // 6
  // New Bubble(31, 5, 0, "2280px", "35%", 0.5),
];

const BubbleElement = ({ item, style = {} }) => {
  const jump = 905;
  const size = 150;

  return (
    <div
      id={`bubble-item-${item.id}`}
      className="shadow-bbc bubble-item absolute inline-block rounded-full bg-no-repeat transition-opacity duration-700"
      style={{
        backgroundImage: "url(/assets/images/common/logo-bubbles.png)",
        backgroundPosition: `${-1 * (size * item.col + item.col)}px ${
          -1 * (size * item.row + item.row)
        }px`,
        backgroundSize: `${jump}px ${jump}px`,
        width: size,
        height: size,
        opacity: 0,
        left: item.left,
        top: item.top,
        transform: `scale(${item.scale})`,
        ...style,
      }}
    />
  );
};

const BubbleLogoRunner = () => {
  const bubbleRef = useRef(null);
  const timer = useRef(null);
  const entry = useIntersectionObserver(bubbleRef, {});

  useEffect(() => {
    const bubblePanel = document.getElementById("bubble-panel");

    if (bubblePanel) {
      bubbles.forEach((item) => {
        item.init();
      });
      const panelScrollHeight = bubblePanel.scrollHeight;
      const runBubbles = () => {
        bubbles.forEach((item) => {
          item.update(panelScrollHeight);
        });
      };

      timer.current = setInterval(() => {
        if (entry?.isIntersecting) {
          runBubbles();
        }
      }, 25);

      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearInterval(timer.current);
      };
    }
  }, [entry?.isIntersecting]);

  // ******
  return (
    <div
      className="relative flex justify-center overflow-hidden"
      style={{ height: "430px" }}
      ref={bubbleRef}
    >
      <div id="bubble-panel" className="relative h-full w-4/5">
        {bubbles.map((item, itemIdx) => (
          <BubbleElement key={itemIdx} item={item} />
        ))}
      </div>
    </div>
  );
};

export default BubbleLogoRunner;
BubbleLogoRunner.propTypes = {};

BubbleLogoRunner.defaultProps = {};
