import { useEffect } from "react";

export const SCREEN = {
  DESKTOP: "DESKTOP",
  TABLET: "TABLET",
  MOBILE: "MOBILE",
};

const useResizeScreenModified = (handler) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScreen = () => {
        if (window?.innerWidth) {
          if (window?.innerWidth <= 1023) {
            try {
              handler(SCREEN.TABLET);
            } catch (error) {
              console.error();
            }
          } else {
            try {
              handler(SCREEN.DESKTOP);
            } catch (error) {
              console.error();
            }
          }
        }
      };

      handleScreen();
      window?.addEventListener("resize", handleScreen);

      return () => {
        /* Clean up function. Remove event before component unmount. */
        window?.removeEventListener("resize", handleScreen);
      };
    }
  }, []);
};

export default useResizeScreenModified;
