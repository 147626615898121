/* eslint-disable */
export default class Bubble {
  constructor(id, row, col, top, left, scale) {
    this.id = id;
    this.row = row;
    this.col = col;
    this.left = left;
    this.top = top;
    this.scale = scale;
    this.mode = id % 2 === 0 ? 1 : -1;
  }

  init = () => {
    const bubbleDom = document.getElementById(`bubble-item-${this.id}`);

    if (bubbleDom) {
      this.runnerX = 0;
      this.runnerY = bubbleDom.offsetTop;
      this.specificTop = bubbleDom.offsetTop;
      this.specificLeft = bubbleDom.offsetLeft;
      bubbleDom.style.opacity = 1;
      this.bubbleDom = bubbleDom;
    }
  };

  update = (panelScrollHeight) => {
    if (this.bubbleDom) {
      if (this.runnerY > -1 * this.bubbleDom.offsetHeight) {
        if (this.mode < 0) {
          this.mode = this.runnerX < -5 ? 1 : -1;
        }
        if (this.mode > 0) {
          this.mode = this.runnerX <= 5 ? 1 : -1;
        }
        this.runnerX += this.mode / (20 * this.scale);
        this.runnerY -= 1;
        this.bubbleDom.style.top = `${this.runnerY}px`;
        this.bubbleDom.style.transform = `translateX(${this.runnerX}px) scale(${this.scale})`;
      } else {
        this.runnerY = panelScrollHeight - this.bubbleDom.offsetHeight;
      }
    }
  };
}
