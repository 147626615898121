import LazyLoad from "react-lazyload";

import PropTypes from "prop-types";

import Link from "components/atoms/LinkRouter";
import Title from "components/atoms/Title";
import BubbleLogoRunner from "components/molecules/BubbleLogoRunner";

const OurPartners = ({ button }) => {
  // ******
  return (
    <section className="mb-0">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center lg:col-6 lg:text-left">
            <Title
              className="title-h2 mb-6 text-bbc-brand-800"
              text="Our Partners"
            />
            <p className="mb-6">
              Join our expanding network of inter-industry professionals in
              business solutions and gain a competitive edge.
            </p>
            {button && (
              <div className="mb-6">
                <Link
                  href="/become-our-partner"
                  className="inline-block rounded-[50px] bg-bbc-primary py-2 px-6 text-center text-base font-semibold text-white transition-all hover:bg-bbc-brand-600"
                >
                  Become Our Partner
                </Link>
              </div>
            )}
          </div>
          <div className="col-12 lg:col-6">
            <LazyLoad height={430}>
              <BubbleLogoRunner />
            </LazyLoad>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurPartners;
OurPartners.propTypes = {
  button: PropTypes.bool,
};

OurPartners.defaultProps = {
  button: false,
};
